import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Desktop } from "./Desktop";
import { useMediaQuery } from "react-responsive";


//

//

const GlobalStyle = createGlobalStyle<any>`
    body{
        background-color:#030103;
        font-family: 'Inter', sans-serif;
        color:#FFFFFF;
        padding: 0;
        margin: 0;
        font-size: 12px;
    }

    

    a{
        text-decoration: none;
        color:#FFFFFF;
        :hover{
            text-decoration: underline;
        }
    }
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background-color:rgba(0,0,0,.2);
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:rgba(0,0,0,.3);
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:rgba(0,0,0,1);
    }
`

export interface GlobalStyle{
    iconW:{w:string,height:string}
}

const desktopTheme={
    iconW:"120px",
    iconH:"55px",
    theme:"desktop",

}

const mobileTheme={
    iconW:"142px",
    iconH:"65px",
    theme:"mobile"
    
}
//iconW:{w:"104px",h:"64px"}

const App = ()=>{
    const isMobile=useMediaQuery({query:'(max-width:1224px)'})
    return <ThemeProvider theme={isMobile?mobileTheme:desktopTheme}>
        <GlobalStyle />
        <Desktop isMobile={isMobile}/>
    </ThemeProvider>
}

export default App;