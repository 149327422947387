import styled from "styled-components"
import { Copyright } from "./Copyright"
import { Lang } from "./Lang"
import { Section } from "./Section"
import { MobileFlashBg } from "./MobileFlashBg"


const ContactsContentDiv = styled.div`
    
    

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                display:flex;
                flex-direction:column;
                gap:20px;
            `
        }else{
            return `
                display: grid;
                grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
                gap:50px;
                font-size: 16px;
            `
        }
    }};


    >div:first-child{
        display: flex;
        flex-direction: column;
        ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                gap:20px;
            `
            }else{
                return `
                    gap:50px;
                `
            }
        }};
        
    }
   
    color: #FFF;
  
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: 0.32px;
`
const BulletDiv = styled.div`
    border: 3px solid #5D5DFF;
    
    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                width: 8px;
                height: 8px;
                min-width: 8px;
                min-height: 8px;
                margin-top:4px;
            `
            }else{
                return `
                width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;

                `
            }
        }};
`

const PubKeyExampleDiv = styled.div`
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: rgba(0,0,0,.5);
    padding: 30px;
    border-radius: 20px;
    color: #FFF;
    text-align: justify;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.4px;

    
`

const ContactItemDiv = styled.div`
    display: flex;
    gap:15px;

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                
            `
        }else{
            return `
                align-items: center;
               
            `
        }
    }};

   
`
const ContactBulletsDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap:15px;
`
const MailA = styled.a`
    color:#FFFFFF;
    text-decoration: none;
    font-weight: bold;
`

const ContactsLineDiv = styled.div`
    display: flex;
    align-items: center;
    gap:20px;
    
    margin-bottom: 60px;
    position: absolute;
    bottom:0;
    width: 100%;

    justify-content: center;

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
            color:#FFF;
            font-size: 12px;
            line-height: 160%;
            text-align:center;
            `
        }else{
            return `
            color:#5D5DFF;
            font-size: 16px;
            line-height: 24px;
            `
        }
    }};

    
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.16px;
`



export const SectionContactUs = ({isMobile}:{isMobile:boolean})=>{
    let i =0;
    const req:string[]=[];
    for(let i=1;i<5;i++){req.push(Lang.get("contact_req"+i))}

    return <Section align="center" bgimg={isMobile?"res/bg_mob_screen":undefined}>

            

            <ContactsContentDiv>
                <div>
                    <div>
                        <div>{Lang.get("contactus")} <MailA href={`mailto:${Lang.get('mail')}`}>{Lang.get('mail')}</MailA></div>
                        <div>{Lang.get('includeInfo')}</div>
                    </div>
                    <ContactBulletsDiv>
                        {req.map(val=>{
                            return <ContactItemDiv key={i++}>
                                <BulletDiv/>
                                {val}
                            </ContactItemDiv>
                        })}
                    </ContactBulletsDiv>
                    <div>{Lang.get('disclaimer')}</div>
                </div>
                {!isMobile && <PubKeyExampleDiv> 
                    {Lang.get('example')}
                </PubKeyExampleDiv>}
            </ContactsContentDiv>

            {!isMobile && <ContactsLineDiv>
                <div>{Lang.get('address')}</div>
                <BulletDiv/>
                <div>{Lang.get('phone')}</div>
                <BulletDiv/>    
                <div>{Lang.get('email')}</div>
            </ContactsLineDiv>}

            {isMobile && <ContactsLineDiv>
                {Lang.get('address')}<br/>
                {Lang.get('phone')}<br/>
                {Lang.get('email')}
                </ContactsLineDiv>}
     
        <Copyright/>
    </Section>
}