import styled from "styled-components"
import GD from "./GD"


const LogoDiv=styled.div<any>`
    width:${({ theme }) => theme.iconW};
    height:${({ theme }) => theme.iconH};
    min-width: ${({ theme }) => theme.iconW};
    min-height: ${({ theme }) => theme.iconH};
    background-image: url("res/recovery_wizards_logo.svg");
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: transform .2s;
    :hover{
        transform: scale(1.1,1.1);
    }
   
`


export const Logo=()=>{
    return <LogoDiv onClick={()=>{GD.S_LOGO_CLICK.invoke()}}/>
}