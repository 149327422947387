import { useEffect, useRef } from "react"
import styled from "styled-components"
import { SignalHandler } from "./utils/Signal"
import GD from "./GD"
import { MobileFlashBg } from "./MobileFlashBg"

interface SectionDivParams{
    nobg?:boolean,
    align?:string
    bgimg?:string
}

const SectionDiv = styled.div<SectionDivParams>`
    display: flex;
    flex-grow: 1;
    position: relative;
    flex-direction: column;
    align-items: center;
    ${props=>{
        if(!props.nobg){
            return `background-color: #030103;`
        }
    }}

   
    

    ${/*TOP OFFSET FOR FIXED MENU*/""}
    ${({ theme }) => {
        if(theme.theme!=="mobile"){
            return `
                padding: 180px 30px 30px 30px;
            `
        }else{
            return `
                padding: 120px 30px 30px 30px;
            `
        }
    }};

    ${props=>{
        if(props.bgimg){
            return `
                background-image:url('${props.bgimg}');
                background-repeat:no-repeat;
                background-position:right bottom;
            `
        }
    }}
   
`

interface SectionContentProps{
    align?:string
    gap?:string

}

const SectionContentDiv = styled.div<SectionContentProps>`
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 1280px;
    width: 100%;

    

    ${props=>{
        if(props.align){
            if(props.align==="bottom")
            return `justify-content:flex-end;`
            if(props.align==="center")
            return `justify-content:center;`
        }
    }}
     ${props=>{
        if(props.gap){
            return `gap:${props.gap};`
        }
    }}

    
    
`




export const Section=(params:{nobg?:boolean,bgimg?:string,bgpos?:"top"|"center"|"bottom",children:any,align?:"bottom"|"center",gap?:string})=>{
        return <SectionDiv nobg={params.nobg} >
                {params.bgimg && <MobileFlashBg position={params.bgpos}/>}
                <SectionContentDiv align={params.align} gap={params.gap} >
                    {params.children}
                </SectionContentDiv>
        </SectionDiv>

    
}