import styled from "styled-components"
import { Lang } from "./Lang"
import { Section } from "./Section"

const ScrollDiv=styled.div`
    overflow-x:scroll;
    overflow-y: hidden;
    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                
                width:calc(100% + 30px);
            `
        }
    }};
`
const ServicesDiv = styled.div`
   display: grid;
      
   ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                grid-template-columns: repeat(4, 85%);
                column-gap: 20px;
            `
        }else{
            return `
                grid-template-columns: repeat(4,minmax(250px,1fr));
                column-gap: 32px;
            `
        }
    }};
`



const ServiceItemDiv = styled.div`
    
    border-radius: 10px;    
    padding:35px;
    display: grid;
    grid-template-rows:max-content max-content;
    row-gap: 20px;
    
    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                border-radius: 20px;  
                background: linear-gradient(180deg, #00000055 0%, #3c1c8b89 100%);
            `
        }else{
            return `
                background: linear-gradient(180deg, #000000 0%, #181843 100%);
            `
        }
    }}

    &[data-icon="1"]>div:first-child{
        background-image: url("res/icon_1.svg");
    }
    &[data-icon="2"]>div:first-child{
        background-image: url("res/icon_2.svg");
    }
    &[data-icon="3"]>div:first-child{
        background-image: url("res/icon_3.svg");
    }
    &[data-icon="4"]>div:first-child{
        background-image: url("res/icon_4.svg");
    }
    

    
   
    >div:first-child{
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: 0.04em;
        text-align: center;
        text-transform: uppercase;
        background-position: center 10px;
        background-repeat: no-repeat;


        ${({ theme }) => {
            if(theme.theme==="mobile"){
                return `
                    background-size:auto 70px;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    line-height: 100%;
                    letter-spacing: 0.8px;
                    border-bottom:4px  solid #5D5DFF;
                    padding:125px 0 20px 0;
                    width:60%;
                    justify-self:center;
                `
            }else{
                return `
                    padding:135px 0 0 0;
                `
            }
        }};

    }
    >div:last-child{
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        ${({ theme }) => {
            if(theme.theme==="mobile"){
                return `
                    color: #FFF;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 220%;
                    letter-spacing: 0.36px;
                `
            }
        }};
    }
`


const QuoteTable=styled.div`
    z-index: 1;
    
    width:80%;
    max-width: 763px;
    align-self: center;
    
    
    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                column-gap: 20px;
                display:flex;
                flex-direction:column;
                font-size:12px;
                line-height: 160%;
            `
        }else{
            return `
                display: grid;
                grid-template-columns: max-content 1fr max-content; 
                gap:52px;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0em;
            `
        }
    }};



    
    font-style: italic;
    font-weight: 400;
    text-align: center;

    
    
   
    >div:first-child,
    >div:last-child{
        display: flex;
        gap:2px;
    }
    >div:last-child{
        align-self:flex-end;
    }
    >div:nth-child(2){
       
        ${({ theme }) => {
            if(theme.theme==="mobile"){
                return `
                    margin-top: 10px;
                    margin-bottom: 0;
                `
            }else{
                return `
                    margin-top: 30px;
                    margin-bottom: 30px;
                `
            }
        }};
    }


`   

const Quote=styled.div`
    width: 21px;
    height: 31px;
    background-image:url("res/quote.svg");
    background-size: contain;
    background-repeat: no-repeat;
   
`



export const SectionDeepLearning = ({isMobile}:{isMobile:boolean} )=>{
    const services:{name?:string|null,value?:string|null}[]=[];
    for(let i=1;i<5;i++){
        services.push({
            name:Lang.get("titleService"+i),
            value:Lang.get("titleServiceDesc"+i),
        })
    }
    let i = 0;
    return <Section align="center" bgpos ="center" bgimg={isMobile?" ":undefined} gap={isMobile?"30px":"120px"} >
        <ScrollDiv>
            <ServicesDiv>
                    {services.map(val=>{
                        return <ServiceItemDiv key={i++} data-icon={(i+1)}>
                            <div>{val.name}</div>
                            <div>{val.value}</div>
                        </ServiceItemDiv>
                    })}
                </ServicesDiv>
        </ScrollDiv>
        <QuoteTable>
            <div><Quote/><Quote/></div>
            <div>{Lang.get("quote")}</div>
            <div><Quote/><Quote/></div>
        </QuoteTable>

    </Section>
}