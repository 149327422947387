import { useEffect } from "react"
import { Lang } from "./Lang"
import { Section } from "./Section"
import { SignalHandler } from "./utils/Signal"
import GD from "./GD"
import styled from "styled-components"

const TitleDiv = styled.div`
    color: #FFF;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 2.4px;
    max-width: 715px;

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                font-size:32px;
                letter-spacing:0.64px;
            `
        }
    }};
`
const SubtitleDiv=styled.div`
    color: #5D5DFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 1.2px;
    margin-bottom: 110px;
    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                margin-bottom: 30px;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.8px;
            `
        }
    }};
`

export const SectionVision=({isMobile}:{isMobile:boolean}) =>{
    // mobile gap 32px
    return <Section nobg align="bottom" bgimg={isMobile?"res/bg_mob_screen.png":undefined} gap={isMobile?"20px":"70px"}>
        <TitleDiv>{Lang.get("titleMain")}</TitleDiv>
        <SubtitleDiv>{Lang.get("subtitleMain")}</SubtitleDiv>
    </Section>
}