export class Lang{
    public static code="en";
    static map:Map<string,string>=new Map();
    static collect(){
        // ENG
        const div = document.getElementById("lang");
        if(!div)
            return;
        const entities=div?.getElementsByTagName("div")
        if(!entities)
            return;
        for(let i of entities){
            if(i && i.id)
                this.map.set(i.id,i.textContent ?? "unknown_"+i.id);
        }
    }

    static get(key:string):string{
        return this.map.get(key) ?? "no_val_"+key;
    }

}