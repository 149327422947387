import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Lang } from './Lang';
import Signal from './utils/Signal';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
Lang.collect();
root.render(<React.StrictMode><App /></React.StrictMode>);

export const S_AUTHOR:Signal<void>=new Signal();
(window as any)["author"] = ()=>{
    console.log("\n----------------------------------\n\tAuthor: Bloom\n\tContact: badmfck@gmail.com\n\tIssued: 2023.07\n\tVersion: 1.0.1\n----------------------------------\n")
    return ":)"
}