import { useEffect, useRef } from "react"
import styled from "styled-components"

const VideoBoxDiv = styled.div`
    position: fixed;
    top:0;
    left:0;
    width:100vw;
    height: 100vh;
    z-index: -1;
    display: flex;
    justify-content: center;
`

const VideoEl = styled.video`
    width:100%;
    height:100%;
   // max-width: 1280px;
    object-fit: cover;
`
export const VideoBG=()=>{
    const ref = useRef<HTMLVideoElement|null>(null)
    useEffect(()=>{
        if(!ref.current)
            return;
        ref.current.muted=true;
        ref.current.loop=true;
        ref.current!.play()
    })
    return <VideoBoxDiv><VideoEl ref={ref} src="res/bg.mp4" loop={true} autoPlay={true}></VideoEl></VideoBoxDiv>
}