import styled from "styled-components";
import { Lang } from "./Lang"
import { Section } from "./Section"



const FAQDiv = styled.div`
    display: flex;
    //flex-grow: 1;
    max-width: 1280px;
    align-self: center;
    flex-direction: column;
    justify-self: center;
    padding: 0 30px 0 30px;
    z-index: 2;
`

const SectionColorFlashDiv = styled.div`
    background-image: url("res/bg_faq.svg");
    background-position: right -300px top 200px;
    background-repeat: no-repeat;
    background-size: auto 80%;
    position: absolute;
    width: 100%;
    height:100%;
    top:-15vh;
    right:0;

`

const FAQItemBoxDiv=styled.div`
    
   
   
    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                display:flex;
                flex-direction:column;
                gap:20px;
            `
        }else{
            return `
                display: grid;
                grid-template-columns: repeat(auto-fit,minmax(400px,1fr));
                gap:70px;
            `
        }
    }};
    
`

const FAQItemDiv=styled.div`
   
    &>div:first-child{
        font-style: italic;
        font-weight: bold;
      
    }

    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                text-align: justify;
                font-size: 12px;
                line-height: 160%;
            `
        }else{
            return `
                line-height: 220%;
                letter-spacing: 0.32px;
                font-size: 16px;
            `
        }
    }};
  
`

const FAQHeadDiv = styled.div`
   
    ${({ theme }) => {
        if(theme.theme==="mobile"){
            return `
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 200%;
                letter-spacing: 0.4px;
            `
        }else{
            return `
                color: #1E1D4E;
                text-align: center;
                font-size: 64px;
                font-weight: 700;
                line-height: 200%;
                letter-spacing: 1.28px;
            `
        }
    }};

`

export const SectionCryptography = ({isMobile}:{isMobile:boolean}) =>{

    const qa:{name?:string|null,value?:string|null}[]=[];
    for(let i=1;i<6;i++){
        qa.push({
            name:Lang.get("faq"+i+"Q"),
            value:Lang.get("faq"+i+"A"),
        })
    }
    let i = 0;
    return <Section align="center" bgpos ="top" bgimg={isMobile?" ":undefined} gap={isMobile?"20px":"130px"} >
        <FAQHeadDiv>FAQ</FAQHeadDiv>
            <FAQItemBoxDiv>
                {qa.map(val=>{
                    return <FAQItemDiv key={i++}> 
                        <div>{val.name}</div>
                        <div>{val.value}</div>
                    </FAQItemDiv>
                })}
            </FAQItemBoxDiv>
    </Section>
}