import styled from "styled-components"

interface Props{
    position?:string;
}
const MobileFlashBgDiv = styled.div<Props>`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background-image: url('res/bg_mob_screen.png');
    mix-blend-mode: screen;
    background-repeat: no-repeat;
    
    opacity: .9;
    ${(props)=>{
        if(props.position){
            if(props.position === "top"){
                return `background-position: right top -200px;`
            }
            if(props.position === "center"){
                return `background-position: right center `
            }
        }
        return `background-position: right bottom`
    }}
`

export const MobileFlashBg = (params:{position?:"top"|"center"|"bottom"}) =>{
    return <MobileFlashBgDiv position={params.position}></MobileFlashBgDiv>
}